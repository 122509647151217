import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFound = () => (
  <div className="not-found">
    <Layout lang="de">
      <Seo title="404 – End of Line" />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 text-color-white content">
            <p className="text-size-3">End of Line.</p>
            <blockquote>
              <p>
                Change the scene!
                <br />
                Alter the mood!
                <br />
                Electrify the boys and girls if you'd be so kind!
              </p>
              <cite>Zuse</cite>
            </blockquote>
            <Link to="/" className="btn text-color-white bg-monday-skyblue">
              Restart
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  </div>
);

export default NotFound;
